@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Dancing+Script&family=El+Messiri&family=Satisfy&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'El Messiri', sans-serif;
}

.bg {
  background: rgb(255, 249, 238);
}

/****************Navbar*****************/
.navbar {
  background-color: #016b8b;
  /* border-bottom: 2px solid orange; */
  border-bottom: 3px solid #ffffff;
}

.nav-link {
  color: #ffffff !important;
  /* font-size: medium !important; */
  font-weight: 500 !important;
  cursor: pointer;
}

@media (max-width: 575px) {
  .nav-link {
    color: #000 !important;
  }
}

.navbar-toggler {
  background-color: #84ac41 !important;
  margin: 3px !important;
  font-size: smaller !important;
}

.fixed {
  transition: .3s ease-in-out;
  position: fixed !important;
  top: 0;
  z-index: 999;
  width: 100%;
}

@media (max-width: 575px) {
  .fixed {
    bottom: -5px;
    top: auto;
  }
}


body {
  background-color: #fffefc;
}

/*******Footer*********/
.footer {
  background-color: #084298;
  padding-top: 20px;
}

a {
  text-decoration: none !important;
}

/****************Banner *******************/
.card-title,
.card-text {
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
  background-color: black;
  opacity: 0.5;
}

.card-box {
  /* margin-bottom: 20px; */
  border: 1px solid orange !important;
  cursor: pointer;
  min-height: 160px;
}

.card-box:hover {
  box-shadow: 0 10px 10px #777;
  /* margin-top: -10px; */
  border: none !important;
  border-top: 2px solid orange !important;
}

.image {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 13px 0px black;
}

.image img {
  /* border: 1px solid #084298;
  height: 150px !important;
  border-radius: 10px; */

}

p {
  font-size: small;
}

.doctor-profile {
  /* height: 100px; */
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
}

.service-icon{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-style: dashed;
  overflow: hidden;
}

.about{
  min-height: 80px;
}

.line {
  width: 200px;
  height: 2px;
  background-color: black;
  margin: 0 auto; /* Centers the line horizontally */

}


@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.blinking-button {
  animation: blink 2s infinite;
}


.text-small{
  font-size: small;
}
.bg-cus {
  background-color: #eed3d7;
}

/* === HEADING STYLE #1 === */
.custom h1 {
  position: relative;
  padding: 0;
  margin: 0;
  /* font-family: "Raleway", sans-serif; */
  font-weight: 400;
  font-size: 32px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  font-style: normal;
  /* font-weight: 600; */
}

.custom h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 2px;
  left: 50%;
  margin-left: -14px;
  background-color: #fdcb4b;
  /* background-color: #b80000; */
}

.custom h1:after {
  width: 100px;
  height: 2px;
  display: block;
  content: "";
  position: relative;
  margin: 15px;
  left: 50%;
  margin-left: -50px;
  background-color: #d4163b;
  /* background-color: #b80000; */
}



/* footer  */


.footer {
  background-color: #24262b;
  padding: 70px 0;
}

.footer-col {
  /* width: 25%; */
  padding: 0 15px;
}

.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer-col h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #e91e63;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}

.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}

.scroll-card{
  height: 10px;
}

/*responsive*/
@media(max-width: 767px) {
  .footer-col {
      /* width: 50%; */
      margin-bottom: 30px;
  }
}

@media(max-width: 574px) {
  .footer-col {
      width: 100%;
  }
}



.MuiFormLabel-asterisk {
  color : red
}



.swal2-container {
  z-index: 9999999;
}